import * as React from 'react';
import { createRoot } from 'react-dom/client';
import find from 'lodash/find';
import MainApp from './App';
import {
  mainnet,
  polygon,
  bsc,
  avalanche,
  fantom,
  arbitrum,
  optimism,
  celo,
  gnosis,
  klaytn,
  aurora,
  cronos,
  okc,
  harmonyOne,
  boba,
  moonriver,
  moonbeam,
  evmos,
  canto,
  polygonZkEvm,
} from 'wagmi/chains';
import {
  injectedWallet,
  rainbowWallet,
  metaMaskWallet,
  coinbaseWallet,
  walletConnectWallet,
  trustWallet,
  argentWallet,
  safeWallet,
  ledgerWallet,
  braveWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { bitkeepWallet, frameWallet, rabbyWallet, ripioWallet } from 'config/custom-wallets';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { getAllChains } from '@mean-finance/sdk';
import { UNSUPPORTED_WAGMI_CHAIN } from 'config/constants';
import { chainToWagmiNetwork } from 'utils/parsing';
import { RainbowKitProvider, connectorsForWallets, darkTheme } from '@rainbow-me/rainbowkit';
import { ThemeProvider } from 'config/themeProvider';
import { Chain as WagmiChain } from 'wagmi/chains';

const sdkChains = getAllChains();

const addedNetworks: WagmiChain[] = [];

UNSUPPORTED_WAGMI_CHAIN.forEach((chainId) => {
  const found = find(sdkChains, { chainId });
  if (found) {
    // @ts-ignore
    addedNetworks.push(chainToWagmiNetwork(found));
  }
});

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [
    mainnet,
    polygon,
    arbitrum,
    optimism,
    bsc,
    avalanche,
    fantom,
    celo,
    gnosis,
    klaytn,
    aurora,
    cronos,
    okc,
    harmonyOne,
    boba,
    moonriver,
    moonbeam,
    evmos,
    canto,
    polygonZkEvm,
    ...addedNetworks,
  ],
  [publicProvider()]
);

const connectors = connectorsForWallets([
  {
    groupName: 'Popular',
    wallets: [
      injectedWallet({ chains }),
      frameWallet({ chains }),
      rabbyWallet({ chains }),
      metaMaskWallet({ chains, projectId: process.env.WC_PROJECT_ID as string }),
      walletConnectWallet({ chains, projectId: process.env.WC_PROJECT_ID as string }),
      rainbowWallet({ chains, projectId: process.env.WC_PROJECT_ID as string }),
      coinbaseWallet({ chains, appName: 'Mean Finance' }),
      braveWallet({ chains }),
    ],
  },
  {
    groupName: 'More',
    wallets: [
      trustWallet({ chains, projectId: process.env.WC_PROJECT_ID as string }),
      ripioWallet({ chains }),
      argentWallet({ chains, projectId: process.env.WC_PROJECT_ID as string }),
      safeWallet({ chains }),
      ledgerWallet({ chains, projectId: process.env.WC_PROJECT_ID as string }),
      bitkeepWallet({ chains }),
    ],
  },
]);

const config = createConfig({
  autoConnect: true,
  publicClient,
  webSocketPublicClient,
  connectors,
});

type AppProps = {};

const App: React.FunctionComponent<AppProps> = () => {
  return (
    <WagmiConfig config={config}>
      <RainbowKitProvider chains={chains} theme={darkTheme()}>
        <ThemeProvider>
          <MainApp />
        </ThemeProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

function bootstrapApplication() {
  const container = document.getElementById('root');
  const root = createRoot(container!); // createRoot(container!) if you use TypeScript
  root.render(<App />);
}

bootstrapApplication();
