import { Chains } from '@mean-finance/sdk';
import { Address } from 'viem';

export const NETWORKS = {
  mainnet: {
    chainId: 1,
    name: 'Ethereum mainnet',
  },
  ropsten: {
    chainId: 3,
    name: 'Ropsten',
  },
  rinkeby: {
    chainId: 4,
    name: 'Rinkeby',
  },
  goerli: {
    chainId: 5,
    name: 'Goerli',
  },
  kovan: {
    chainId: 42,
    name: 'Kovan',
  },
  meanfinance: {
    chainId: 31337,
    name: 'Mean Finance',
  },
  bsc: {
    chainId: 56,
    name: 'BNB',
    mainCurrency: 'BNB',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpc: [
      'https://bsc-dataseed1.binance.org',
      'https://bsc-dataseed2.binance.org',
      'https://bsc-dataseed3.binance.org',
      'https://bsc-dataseed4.binance.org',
      'https://bsc-dataseed1.defibit.io',
      'https://bsc-dataseed2.defibit.io',
      'https://bsc-dataseed3.defibit.io',
      'https://bsc-dataseed4.defibit.io',
      'https://bsc-dataseed1.ninicoin.io',
      'https://bsc-dataseed2.ninicoin.io',
      'https://bsc-dataseed3.ninicoin.io',
      'https://bsc-dataseed4.ninicoin.io',
      'wss://bsc-ws-node.nariox.org',
    ],
  },
  mumbai: {
    chainId: 80001,
    name: 'Mumbai',
    mainCurrency: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpc: ['https://matic-mumbai.chainstacklabs.com'],
  },
  polygon: {
    chainId: 137,
    name: 'Polygon',
  },
  fantom: {
    chainId: 250,
    name: 'Fantom',
  },
  avalanche: {
    chainId: 43114,
    name: 'Avalanche',
  },
  arbitrum: {
    chainId: 42161,
    name: 'Arbitrum',
  },
  heco: {
    chainId: 128,
    name: 'Heco',
  },
  optimism: {
    chainId: 10,
    name: 'Optimism',
    mainCurrency: '0x4200000000000000000000000000000000000006',
  },
  optimismKovan: {
    chainId: 69,
    name: 'Optimism Kovan',
    mainCurrency: '0x4200000000000000000000000000000000000006',
  },
  optimismGoerli: {
    chainId: 420,
    name: 'Optimism Goerli',
    mainCurrency: '0x4200000000000000000000000000000000000006',
  },
  okex: {
    chainId: 66,
    name: 'OKEx',
  },
  harmony: {
    chainId: 1666600000,
    name: 'Harmony',
  },
  xdai: {
    chainId: 100,
    name: 'xDAI',
  },
  baseGoerli: {
    chainId: 84531,
    name: 'Base Goerli',
    mainCurrency: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    mainColor: '#3076F6',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpc: ['https://goerli.base.org', 'https://base-goerli.public.blastapi.io'],
  },
};

export const SUPPORTED_NETWORKS = [
  ...Object.values(Chains).map(chain => chain.chainId),
  NETWORKS.mainnet.chainId,
  NETWORKS.optimism.chainId,
  NETWORKS.ropsten.chainId,
  NETWORKS.rinkeby.chainId,
  NETWORKS.goerli.chainId,
  NETWORKS.kovan.chainId,
  NETWORKS.mumbai.chainId,
  NETWORKS.polygon.chainId,
  NETWORKS.baseGoerli.chainId,
  NETWORKS.bsc.chainId,
];

export const SWAPPER_ADDRESS: Record<number, Address> = {
  ...SUPPORTED_NETWORKS.reduce((acc, chain) => ({ ...acc, [chain]: '0x92A6B64544643a70aC3Fbb825524A3138C4CAC57' }), {}),
  [NETWORKS.kovan.chainId]: '0x92A6B64544643a70aC3Fbb825524A3138C4CAC57',
  [NETWORKS.optimismKovan.chainId]: '0x92A6B64544643a70aC3Fbb825524A3138C4CAC57',
  [NETWORKS.optimism.chainId]: '0x92A6B64544643a70aC3Fbb825524A3138C4CAC57',
  [NETWORKS.mumbai.chainId]: '0x92A6B64544643a70aC3Fbb825524A3138C4CAC57',
  [NETWORKS.polygon.chainId]: '0x92A6B64544643a70aC3Fbb825524A3138C4CAC57',
  [NETWORKS.arbitrum.chainId]: '0x92A6B64544643a70aC3Fbb825524A3138C4CAC57',
  [NETWORKS.mainnet.chainId]: '0x92A6B64544643a70aC3Fbb825524A3138C4CAC57',
  [NETWORKS.baseGoerli.chainId]: '0x92A6B64544643a70aC3Fbb825524A3138C4CAC57',
  [NETWORKS.bsc.chainId]: '0x92A6B64544643a70aC3Fbb825524A3138C4CAC57',
  [Chains.ROOTSTOCK.chainId]: '0xAac9001f7A5F072e48CDDaf538c52c45b7117A70',
};

export const HUB_ADDRESS: Record<number, Address> = {
  ...SUPPORTED_NETWORKS.reduce((acc, chain) => ({ ...acc, [chain]: '0xA5AdC5484f9997fBF7D405b9AA62A7d88883C345' }), {}),
  [NETWORKS.kovan.chainId]: '0xA5AdC5484f9997fBF7D405b9AA62A7d88883C345',
  [NETWORKS.optimismKovan.chainId]: '0xA5AdC5484f9997fBF7D405b9AA62A7d88883C345',
  [NETWORKS.optimism.chainId]: '0xA5AdC5484f9997fBF7D405b9AA62A7d88883C345',
  [NETWORKS.mumbai.chainId]: '0xA5AdC5484f9997fBF7D405b9AA62A7d88883C345',
  [NETWORKS.polygon.chainId]: '0xA5AdC5484f9997fBF7D405b9AA62A7d88883C345',
  [NETWORKS.arbitrum.chainId]: '0xA5AdC5484f9997fBF7D405b9AA62A7d88883C345',
  [NETWORKS.mainnet.chainId]: '0xA5AdC5484f9997fBF7D405b9AA62A7d88883C345',
  [NETWORKS.baseGoerli.chainId]: '0xA5AdC5484f9997fBF7D405b9AA62A7d88883C345',
  [NETWORKS.bsc.chainId]: '0xA5AdC5484f9997fBF7D405b9AA62A7d88883C345',
  [Chains.ROOTSTOCK.chainId]: '0x8CC0Df843610cefF7f4AFa01100B6abf6756Bdf2',
};

export const PERMISSION_MANAGER_ADDRESS: Record<number, Address> = {
  ...SUPPORTED_NETWORKS.reduce((acc, chain) => ({ ...acc, [chain]: '0x20bdAE1413659f47416f769a4B27044946bc9923' }), {}),
  [NETWORKS.kovan.chainId]: '0x20bdAE1413659f47416f769a4B27044946bc9923',
  [NETWORKS.optimismKovan.chainId]: '0x20bdAE1413659f47416f769a4B27044946bc9923',
  [NETWORKS.optimism.chainId]: '0x20bdAE1413659f47416f769a4B27044946bc9923',
  [NETWORKS.mumbai.chainId]: '0x20bdAE1413659f47416f769a4B27044946bc9923',
  [NETWORKS.polygon.chainId]: '0x20bdAE1413659f47416f769a4B27044946bc9923',
  [NETWORKS.arbitrum.chainId]: '0x20bdAE1413659f47416f769a4B27044946bc9923',
  [NETWORKS.mainnet.chainId]: '0x20bdAE1413659f47416f769a4B27044946bc9923',
  [NETWORKS.baseGoerli.chainId]: '0x20bdAE1413659f47416f769a4B27044946bc9923',
  [NETWORKS.bsc.chainId]: '0x20bdAE1413659f47416f769a4B27044946bc9923',
  [Chains.ROOTSTOCK.chainId]: '0x1EE410Fc840cC13C4e1b17DC6f93E245a918c19e',

};
export const COMPANION_ADDRESS: Record<number, Address> = {
  ...SUPPORTED_NETWORKS.reduce((acc, chain) => ({ ...acc, [chain]: '0x6C615481E96806edBd9987B6E522A4Ea85d13659' }), {}),
  [NETWORKS.kovan.chainId]: '0x6C615481E96806edBd9987B6E522A4Ea85d13659',
  [NETWORKS.optimismKovan.chainId]: '0x6C615481E96806edBd9987B6E522A4Ea85d13659',
  [NETWORKS.optimism.chainId]: '0x6C615481E96806edBd9987B6E522A4Ea85d13659',
  [NETWORKS.mumbai.chainId]: '0x6C615481E96806edBd9987B6E522A4Ea85d13659',
  [NETWORKS.polygon.chainId]: '0x6C615481E96806edBd9987B6E522A4Ea85d13659',
  [NETWORKS.arbitrum.chainId]: '0x6C615481E96806edBd9987B6E522A4Ea85d13659',
  [NETWORKS.mainnet.chainId]: '0x6C615481E96806edBd9987B6E522A4Ea85d13659',
  [NETWORKS.baseGoerli.chainId]: '0x6C615481E96806edBd9987B6E522A4Ea85d13659',
  [NETWORKS.bsc.chainId]: '0x6C615481E96806edBd9987B6E522A4Ea85d13659',
  [Chains.ROOTSTOCK.chainId]: '0x5872E8D5Ec9Dbf67949FdD4B5e05707644D60876',

};

export const ORACLE_ADDRESS: Record<number, Address> = {
  ...SUPPORTED_NETWORKS.reduce((acc, chain) => ({ ...acc, [chain]: '0x9e1ca4Cd00ED059C5d34204DCe622549583545d9' }), {}),
  [NETWORKS.kovan.chainId]: '0x9e1ca4Cd00ED059C5d34204DCe622549583545d9',
  [NETWORKS.optimismKovan.chainId]: '0x9e1ca4Cd00ED059C5d34204DCe622549583545d9',
  [NETWORKS.optimism.chainId]: '0x9e1ca4Cd00ED059C5d34204DCe622549583545d9',
  [NETWORKS.mumbai.chainId]: '0x9e1ca4Cd00ED059C5d34204DCe622549583545d9',
  [NETWORKS.polygon.chainId]: '0x9e1ca4Cd00ED059C5d34204DCe622549583545d9',
  [NETWORKS.arbitrum.chainId]: '0x9e1ca4Cd00ED059C5d34204DCe622549583545d9',
  [NETWORKS.mainnet.chainId]: '0x9e1ca4Cd00ED059C5d34204DCe622549583545d9',
  [NETWORKS.baseGoerli.chainId]: '0x9e1ca4Cd00ED059C5d34204DCe622549583545d9',
  [NETWORKS.bsc.chainId]: '0x9e1ca4Cd00ED059C5d34204DCe622549583545d9',
  [Chains.ROOTSTOCK.chainId]: '0xDf25497c44716E6aee8D7Ba6726E0EF2eD3F9b39',

};

export const CHAINLINK_ORACLE_ADDRESS: Record<number, Address> = {
  ...SUPPORTED_NETWORKS.reduce((acc, chain) => ({ ...acc, [chain]: '0x5587d300d41E418B3F4DC7c273351748a116d78B' }), {}),
  [NETWORKS.kovan.chainId]: '0x5587d300d41E418B3F4DC7c273351748a116d78B',
  [NETWORKS.optimismKovan.chainId]: '0x5587d300d41E418B3F4DC7c273351748a116d78B',
  [NETWORKS.optimism.chainId]: '0x5587d300d41E418B3F4DC7c273351748a116d78B',
  [NETWORKS.mumbai.chainId]: '0x5587d300d41E418B3F4DC7c273351748a116d78B',
  [NETWORKS.polygon.chainId]: '0x5587d300d41E418B3F4DC7c273351748a116d78B',
  [NETWORKS.arbitrum.chainId]: '0x5587d300d41E418B3F4DC7c273351748a116d78B',
  [NETWORKS.mainnet.chainId]: '0x5587d300d41E418B3F4DC7c273351748a116d78B',
  [NETWORKS.baseGoerli.chainId]: '0x5587d300d41E418B3F4DC7c273351748a116d78B',
  [NETWORKS.bsc.chainId]: '0x5587d300d41E418B3F4DC7c273351748a116d78B',
  [Chains.ROOTSTOCK.chainId]: '0x5d1d55a00A72E0E0d0aFc973bfeDa603f5E11649',

};

export const MULTICALL_ADDRESS: Record<number, Address> = {
  ...SUPPORTED_NETWORKS.reduce((acc, chain) => ({ ...acc, [chain]: '0xcA11bde05977b3631167028862bE2a173976CA11' }), {}),
  [NETWORKS.optimism.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [NETWORKS.polygon.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [NETWORKS.arbitrum.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [NETWORKS.mainnet.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [NETWORKS.baseGoerli.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [NETWORKS.bsc.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [Chains.ROOTSTOCK.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11',

};

export const UNISWAP_ORACLE_ADDRESS: Record<number, Address> = {
  ...SUPPORTED_NETWORKS.reduce((acc, chain) => ({ ...acc, [chain]: '0x14AF365e0825B835C60867C985724e1DF11449ad' }), {}),
  [NETWORKS.kovan.chainId]: '0x14AF365e0825B835C60867C985724e1DF11449ad',
  [NETWORKS.optimismKovan.chainId]: '0x14AF365e0825B835C60867C985724e1DF11449ad',
  [NETWORKS.optimism.chainId]: '0x14AF365e0825B835C60867C985724e1DF11449ad',
  [NETWORKS.mumbai.chainId]: '0x14AF365e0825B835C60867C985724e1DF11449ad',
  [NETWORKS.polygon.chainId]: '0x14AF365e0825B835C60867C985724e1DF11449ad',
  [NETWORKS.arbitrum.chainId]: '0x14AF365e0825B835C60867C985724e1DF11449ad',
  [NETWORKS.mainnet.chainId]: '0x14AF365e0825B835C60867C985724e1DF11449ad',
  [NETWORKS.baseGoerli.chainId]: '0x14AF365e0825B835C60867C985724e1DF11449ad',
  [NETWORKS.bsc.chainId]: '0x14AF365e0825B835C60867C985724e1DF11449ad',
};

export enum PositionVersions {
  BETA = 'Beta',
  VULN = 'Vulnerable',
  YIELDLESS = 'Yieldless',
  YIELD = 'Yield',
}

export const LATEST_VERSION = PositionVersions.YIELD;
type AddressMap<K extends PositionVersions> = {
  [k in K]: Record<number, string>;
};

export const MEAN_GRAPHQL_URL: AddressMap<PositionVersions> = {
  [PositionVersions.BETA]: {
    [NETWORKS.optimism.chainId]: 'https://api.thegraph.com/subgraphs/name/mean-finance/dca-v2-ys-beta-optimism',
  },
  [PositionVersions.VULN]: {
    [NETWORKS.optimism.chainId]: 'https://api.thegraph.com/subgraphs/name/mean-finance/dca-v2-ys-vulnerable-optimism',
    [NETWORKS.polygon.chainId]: 'https://api.thegraph.com/subgraphs/name/mean-finance/dca-v2-ys-vulnerable-polygon',
  },
  [PositionVersions.YIELDLESS]: {
    [NETWORKS.optimism.chainId]: 'https://api.thegraph.com/subgraphs/name/mean-finance/dca-v2-ys-optimism',
    [NETWORKS.polygon.chainId]: 'https://api.thegraph.com/subgraphs/name/mean-finance/dca-v2-ys-polygon',
  },
  [PositionVersions.YIELD]: {
    [NETWORKS.polygon.chainId]: 'https://api.thegraph.com/subgraphs/name/mean-finance/dca-v2-yf-polygon',
    [NETWORKS.optimism.chainId]: 'https://api.thegraph.com/subgraphs/name/mean-finance/dca-v2-yf-optimism',
    [NETWORKS.arbitrum.chainId]:
      'https://gateway-arbitrum.network.thegraph.com/api/1cba15dc3274409e986816ec542304a1/subgraphs/id/GbPDs4fL2KJZG4ghy5NdNwUKGtTXTXmRCZvbTW48FA2u',
    [NETWORKS.mainnet.chainId]:
      'https://gateway-arbitrum.network.thegraph.com/api/1cba15dc3274409e986816ec542304a1/subgraphs/id/9nAX9x7DTgoEFtNE7a8j2JaviYc21qNAeCUXn7VAZsda',
    [NETWORKS.bsc.chainId]: 'https://api.thegraph.com/subgraphs/name/mean-finance/dca-v2-yf-bnb',
    [NETWORKS.xdai.chainId]:
      'https://gateway-arbitrum.network.thegraph.com/api/1cba15dc3274409e986816ec542304a1/subgraphs/id/5jU3dz1cY49JrnvZZsWCufAMLJwfa8hyd9uJuUoYXGNu',
    [NETWORKS.baseGoerli.chainId]: 'https://api.thegraph.com/subgraphs/name/mean-finance/dca-v2-yf-base-goerli',
    [Chains.MOONBEAM.chainId]: 'https://api.thegraph.com/subgraphs/name/mean-finance/dca-v2-yf-moonbeam',
  },
};

export const EXPLORER_URL = {
  [NETWORKS.mainnet.chainId]: 'https://etherscan.io/',
  [NETWORKS.ropsten.chainId]: 'https://ropsten.etherscan.io/',
  [NETWORKS.rinkeby.chainId]: 'https://rinkeby.etherscan.io/',
  [NETWORKS.goerli.chainId]: 'https://goerli.etherscan.io/',
  [NETWORKS.kovan.chainId]: 'https://kovan.etherscan.io/',
  [NETWORKS.meanfinance.chainId]: 'https://etherscan.io/',
  [NETWORKS.bsc.chainId]: 'https://bscscan.com/',
  [NETWORKS.polygon.chainId]: 'https://polygonscan.com/',
  [NETWORKS.fantom.chainId]: 'https://ftmscan.com/',
  [NETWORKS.avalanche.chainId]: 'https://cchain.explorer.avax.network/',
  [NETWORKS.mumbai.chainId]: 'https://mumbai.polygonscan.com/',
  [NETWORKS.arbitrum.chainId]: 'https://arbiscan.io/',
  [NETWORKS.heco.chainId]: 'https://scan.hecochain.com/',
  [NETWORKS.optimism.chainId]: 'https://optimistic.etherscan.io/',
  [NETWORKS.okex.chainId]: 'https://www.oklink.com/okexchain/',
  [NETWORKS.harmony.chainId]: 'https://explorer.harmony.one/#/',
  [NETWORKS.xdai.chainId]: 'https://blockscout.com/xdai/mainnet/',
  [NETWORKS.optimismKovan.chainId]: 'https://kovan-optimistic.etherscan.io/',
  [NETWORKS.optimism.chainId]: 'https://optimistic.etherscan.io/',
  [NETWORKS.baseGoerli.chainId]: 'https://goerli.basescan.org/',
  [Chains.ROOTSTOCK.chainId]: Chains.ROOTSTOCK.explorer,
};

export const TOKEN_LISTS = {
  'tokens.1inch.eth': {
    name: '1inch',
    homepage: '',
  },
  'https://www.gemini.com/uniswap/manifest.json': {
    name: 'Gemini Token List',
    homepage: 'https://www.gemini.com/',
  },
  'https://gateway.ipfs.io/ipns/tokens.uniswap.org': {
    name: 'Uniswap Default List',
    homepage: '',
  },
  'https://tokens.coingecko.com/uniswap/all.json': {
    name: 'CoinGecko',
    homepage: '',
  },
};

export const STABLE_COINS = ['DAI', 'USDT', 'USDC', 'BUSD', 'UST'];

const ONE_MINUTE = 60;
const FIVE_MINUTES = ONE_MINUTE * 5;
const FIFTEEN_MINUTES = FIVE_MINUTES * 3;
const THIRTY_MINUTES = FIFTEEN_MINUTES * 2;
const ONE_HOUR = THIRTY_MINUTES * 2;
const FOUR_HOURS = ONE_HOUR * 4;
const ONE_DAY = FOUR_HOURS * 6;
const ONE_WEEK = ONE_DAY * 7;

export const SWAP_INTERVALS = [
  {
    description: 'One minute',
    key: 1,
    value: ONE_MINUTE,
  },
  {
    description: 'Five minutes',
    key: 2,
    value: FIVE_MINUTES,
  },
  {
    description: 'Fifteen minutes',
    key: 4,
    value: FIFTEEN_MINUTES,
  },
  {
    description: 'Thirty minutes',
    key: 8,
    value: THIRTY_MINUTES,
  },
  {
    description: 'One hour',
    key: 16,
    value: ONE_HOUR,
  },
  {
    description: 'Four hours',
    key: 32,
    value: FOUR_HOURS,
  },
  {
    description: 'One day',
    key: 64,
    value: ONE_DAY,
  },
  {
    description: 'One week',
    key: 128,
    value: ONE_WEEK,
  },
];

export const MEAN_API_URL = 'https://api.balmy.xyz';

export const DEFILLAMA_IDS = {
  [NETWORKS.mainnet.chainId]: 'ethereum',
  [NETWORKS.polygon.chainId]: 'polygon',
  [NETWORKS.optimism.chainId]: 'optimism',
  [NETWORKS.mumbai.chainId]: 'mumbai',
  [NETWORKS.baseGoerli.chainId]: 'mumbai',
  [NETWORKS.bsc.chainId]: 'mumbai',
};

export const COINGECKO_IDS = {
  [NETWORKS.mainnet.chainId]: 'ethereum',
  [NETWORKS.ropsten.chainId]: 'ethereum',
  [NETWORKS.rinkeby.chainId]: 'ethereum',
  [NETWORKS.goerli.chainId]: 'ethereum',
  [NETWORKS.kovan.chainId]: 'ethereum',
  [NETWORKS.polygon.chainId]: 'polygon-pos',
  [NETWORKS.optimism.chainId]: 'optimistic-ethereum',
  [NETWORKS.optimismGoerli.chainId]: 'optimistic-ethereum',
  [NETWORKS.optimismKovan.chainId]: 'optimistic-ethereum',
  [NETWORKS.mumbai.chainId]: 'mumbai',
  [NETWORKS.baseGoerli.chainId]: 'base-goerli',
  [NETWORKS.bsc.chainId]: 'bsc',
};

export const UNI_GRAPHQL_URL = {
  [NETWORKS.kovan.chainId]: 'https://api.thegraph.com/subgraphs/name/fibofinance/uniswap-v3-kovan',
  [NETWORKS.optimismKovan.chainId]: 'https://api.thegraph.com/subgraphs/name/storres93/uniswap-v3-optimism-kovan',
  [NETWORKS.optimism.chainId]: 'https://api.thegraph.com/subgraphs/name/ianlapham/optimism-post-regenesis',
  [NETWORKS.mumbai.chainId]: 'https://api.thegraph.com/subgraphs/name/ianlapham/optimism-post-regenesis',
  [NETWORKS.polygon.chainId]: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon',
};

export const ZERO_EX_API_URL = {
  [NETWORKS.mainnet.chainId]: 'https://api.0x.org/',
  [NETWORKS.ropsten.chainId]: 'https://ropsten.api.0x.org/',
  [NETWORKS.polygon.chainId]: 'https://polygon.api.0x.org/',
  [NETWORKS.bsc.chainId]: 'https://bsc.api.0x.org/',
  [NETWORKS.optimism.chainId]: 'https://optimism.api.0x.org/',
  [NETWORKS.fantom.chainId]: 'https://fantom.api.0x.org/',
  [NETWORKS.avalanche.chainId]: 'https://avalanche.api.0x.org/',
};

export const UNSUPPORTED_WAGMI_CHAIN = [122, 128, 106, 42262, 30];

export const DCA_NETWORKS = [
  Chains.OPTIMISM.chainId,
  Chains.ARBITRUM.chainId,
  Chains.ETHEREUM.chainId,
  Chains.BNB_CHAIN.chainId,
  Chains.POLYGON.chainId,
  Chains.MOONBEAM.chainId,
  Chains.ROOTSTOCK.chainId,
];
